import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";
import { useQuery } from "react-query";

const useGetRandomProducts = () => {
  const navigate = useNavigate();

  const onProductErr = (error) => {
    if (error) {
      navigate("/no-connection");
    }
  };

  const getAllProducts = async () => {
    const response = await axiosInstance.get(
      `/product?productStatus=APPROVED&random=true`
    );

    return response?.data?.data.products;
  };

  const { data: allProducts, isLoading: loading } = useQuery(
    "randomizedProduct",
    getAllProducts,
    {
      onError: onProductErr,

      cacheTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    loading,
    allProducts,
  };
};

export default useGetRandomProducts;
