import React, { createContext, useContext, useEffect, useState } from "react";
import { axiosInstance } from "../baseUrl";
import { GlobalContext } from "./GlobalState";

export const AliexpressCartContext = createContext();

const AliexpressCartState = ({ children }) => {
  const { user } = useContext(GlobalContext);

  const [rerenderCart, setRerenderCart] = useState(false);
  const [totalShippingFee, setTotalShippingFee] = useState(0);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });



  // const getTotalQuantity = () => {
  //   return cart.reduce((total, item) => total + item.quantity, 0);
  // };

  // const getTotalQuantity = useCallback(() => {
  //   return cart.reduce((total, item) => total + item.quantity, 0);
  // }, [cart]);



  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    } else {
      setCart([]);
    }
  }, [rerenderCart]);

  useEffect(() => {
    const fetchShippingDetails = async () => {
      let totalFee = 0;
      let locationDetails = {
        country: "",
        countyShort: "",
      };
      const address = user?.address;

      if (!address) {
        return;
      }

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );

      const data = await response.json();

      if (data.status !== "OK") {
        return;
      }
      const addressComponents = data.results[0].address_components;

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("country")) {
          locationDetails.country = component.long_name;
        }
        if (types.includes("country")) {
          locationDetails.countyShort = component.short_name;
        }
      });

      const updatedCart = await Promise.all(
        cart.map(async (product) => {
          const requestData = {
            queryDeliveryReq: {
              quantity: product.quantity,
              shipToCountry: locationDetails?.countyShort,
              productId: product.productId,
              language: "en",
              source: "CN",
              locale: "en_US",
              selectedSkuId: product?.sku_id,
              currency: "USD",
            },
          };

          try {
            const response = await axiosInstance.post(
              "/ali-express/freight/query",
              requestData
            );

            const result =
              response.data.aliexpress_ds_freight_query_response.result;

            if (!result.success) {
              // Update product when shipping isn't allowed to destination
              const updatedProduct = {
                ...product,
                shipping: "DELIVERY NOT AVAILABLE TO YOUR ADDRESS",
                freeShipping: null,
                shippingFee: null,
                maxDeliveryDuration: null,
                minDeliveryDuration: null,
              };
              return updatedProduct;
            }

            const deliveryOptions =
              result?.delivery_options?.delivery_option_d_t_o;

            if (deliveryOptions && deliveryOptions.length > 0) {
              // Use the first available delivery option
              const selectedOption = deliveryOptions[0];

              let shippingFee = 0;

              // Check if the option has free shipping
              if (selectedOption.free_shipping) {
                shippingFee = 0;
              } else if (selectedOption.shipping_fee_cent) {
                shippingFee = parseFloat(selectedOption.shipping_fee_cent);
              }

              // Update product with shipping fee and delivery duration
              const updatedProduct = {
                ...product,
                shipping: "DELIVERY AVAILABLE TO YOUR ADDRESS",
                freeShipping: selectedOption?.free_shipping,
                shippingFee,
                maxDeliveryDuration: selectedOption?.max_delivery_days,
                minDeliveryDuration: selectedOption?.min_delivery_days,
              };
              // Add the shipping fee to the total fee
              totalFee += shippingFee;

              return updatedProduct;
            }

            return product;
          } catch (error) {
            console.error("Error fetching shipping details", error);
            return product;
          }
        })
      );

      setCart(updatedCart);
      setTotalShippingFee(totalFee.toFixed(2));
    };

    if (cart.length > 0) {
      fetchShippingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerenderCart, user?.address]);

  const value = {
    cart,
    setCart,
    setRerenderCart,
    totalShippingFee,
  };

  return (
    <AliexpressCartContext.Provider value={value}>
      {children}
    </AliexpressCartContext.Provider>
  );
};

export default AliexpressCartState;
