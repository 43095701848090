import React, { useContext } from "react";
import { Link } from "react-router-dom";

import ProductSkeleton from "../SkeletonLoader/ProductSkeleton";

import { useEventTag } from "../hooks/useEventTag";
import { useGetPopularProducts } from "../hooks/useGetPopularProducts";
import { CurrencyExchangeContext } from "../utils/CurrencyExchangeState";
import { Capitalize, numberWithCommas } from "../helpers/functionHelpers";

const PopularProducts = ({ sectionTitle, selectedCurrency, user }) => {
  const { loading, allProducts } = useGetPopularProducts();
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const ref = React.useRef(null);
  const { handleEventTag } = useEventTag();

  const handleEventAction = (productName, productId) => {
    if (user) {
      handleEventTag(`p_${productName}`, user.id, productId);
    } else {
      handleEventTag(`p_${productName}`, null, productId);
    }
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <>
      {loading ? (
        <ProductSkeleton />
      ) : (
        <>
          <div className="section-head">
            <h2>{sectionTitle}</h2>
            <div>
              <i
                className="fas fa-chevron-left me-4 scroll-icon"
                onClick={() => scroll(-275)}
              ></i>
              <i
                className="fas fa-chevron-right scroll-icon "
                onClick={() => scroll(275)}
              ></i>
            </div>
          </div>

          <div className="main-container">
            <div className="p-container" ref={ref}>
              {allProducts &&
                allProducts.map((product) => (
                  <Link
                    to={`/product-info/${product.id && product.id}`}
                    key={product.id && product.id}
                    onClick={() =>
                      handleEventAction(product.productName, product.id)
                    }
                  >
                    <div className="product-card">
                      <div className="product-card-img">
                        <img
                          src={
                            product.productImages[0] &&
                            product.productImages[0].image
                          }
                          className=""
                          alt="..."
                        />
                      </div>
                      <div className="product-card-info">
                        <h3 className="seller-name">
                          {product && Capitalize(product.createdBy.firstName)}{" "}
                          {product && Capitalize(product.createdBy.LastName)}
                        </h3>
                        {product && product.productName.length > 20 ? (
                          <h2 className={"product-name"}>
                            {Capitalize(product.productName).slice(0, 20)}...
                          </h2>
                        ) : (
                          <h2 className={"product-name"}>
                            {Capitalize(product.productName)}
                          </h2>
                        )}
                        <p className="product-price">
                          <span className="p-currency">{selectedCurrency}</span>{" "}
                          {product.minPricePerUnit &&
                            numberWithCommas(
                              convertCurrency(
                                product.minPricePerUnit,
                                product?.currencyType,
                                selectedCurrency
                              )
                            )}
                          <span className="p-unit">
                            / {product && product.unitForMinOrder}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PopularProducts;
